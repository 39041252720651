import Lot from '~/models/lot'
import { LotInfo, RentalUnit } from '~/models'
import { $axios } from '~/utils/api'
import PagedResponse from '~/models/pagedResponse'

const END_POINT = '/map'

const parseHomeParams = (params: any): string => {
  let query = ''

  if (params.page) query += `&page=${params.page}`
  if (params.beds) query += `&beds=${params.beds}`
  if (params.baths) query += `&baths=${params.baths}`
  if (params.stories) query += `&stories=${params.stories}`
  if (params.garages) query += `&garages=${params.garages}`
  if (params.sqft_min) query += `&sqft_min=${params.sqft_min}`
  if (params.sqft_max) query += `&sqft_max=${params.sqft_max}`
  if (params.price_min) query += `&price_range_min=${params.price_min}`
  if (params.price_max) query += `&price_range_max=${params.price_max}`
  if (params.move_in_date) query += `&move_in_date=${params.move_in_date}`
  if (params.floor_plan) query += `&floor_plan=${params.floor_plan}`
  return query
}

const getProject = (slug: string) =>
  $axios.$get(`${END_POINT}/consumer/projects/${slug}`)

const getParcelIds = (queryParams?: string): Promise<Array<string>> => {
  return $axios.$get(
    `${END_POINT}/parcel_ids${queryParams ? `?${queryParams}` : ''}`
  )
}

const getLot = (
  parcelId: string,
  project: string
): Promise<PagedResponse<Lot>> =>
  $axios.$get(`${END_POINT}/lots?parcel_id=${parcelId}&project=${project}`)

const getRentalLotAsync = (
  parcelId: string,
  project: string
): Promise<RentalUnit> =>
  $axios.$get(
    `${END_POINT}/rental/homes?parcel_id=${parcelId}&project=${project}`
  )

const getLotsStatusAsync = (
  slug: string,
  params: any = {}
): Promise<{
  available: Array<string>
  reserved: Array<String>
  unavailable: Array<String>
}> => {
  let url = `${END_POINT}/rental/lots?project=${slug}`
  url += parseHomeParams(params)

  return $axios.$get(url)
}

const getRentalUnits = (
  slug: string,
  params: any
): Promise<PagedResponse<RentalUnit>> => {
  let url = `${END_POINT}/rental/homes?project=${slug}`

  url += parseHomeParams(params)

  return $axios.$get(url)
}

const getRentalUnitsV2 = (
  slug: string,
  params: any
): Promise<PagedResponse<RentalUnit>> => {
  let url = `v2${END_POINT}/rental/homes?project=${slug}`

  url += parseHomeParams(params)

  return $axios.$get(url)
}

// The end point for consumer map and rental map are the same thing.
const getModels = (slug: string): Promise<Lot[]> => {
  const url = `${END_POINT}/consumer/models?project=${slug}`
  return $axios.$get(url)
}

const getBulkRentalProperties = (
  slug: string,
  data: any
): Promise<LotInfo[]> => {
  const url = `${END_POINT}/rental/properties?project=${slug}`
  return $axios.$post(url, data)
}

const getRentalFilters = (
  slug: string,
  filter: string
): Promise<Array<{ value: string; label: string }>> =>
  // This doesn't get renamed to /rental/ because it's the same end point.
  $axios.$get(`${END_POINT}/rental/filters/${filter}?project=${slug}`)

export {
  getProject,
  getParcelIds,
  getLot,
  getModels,
  getLotsStatusAsync,
  getRentalLotAsync,
  getRentalUnits,
  getRentalUnitsV2,
  getRentalFilters,
  parseHomeParams,
  getBulkRentalProperties,
}
